import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
declare var require: any;
let config = require("../../assets/config");

@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  token;
  userEmail: any;
  userId: any;
  userType: any;
  name: any;
  logged: boolean;
  policyUrl: any;
  linkedInUrl: any;
  analyticsUrl: any;
  linkedInClientID: any;
  url: any;
  wsUrl: any;
  timeoutOfflineModal = 2000;
  timeoutConnectivityModal = 10000;

  assetsPath = "../../assets/";
  carouselPath = this.assetsPath + "carousel/";
  webIconsPath = this.assetsPath + "webIcons/";
  countryFlags = this.assetsPath + "new_flags/";
  chromeVersion = "72";
  edgeVersion = "81";
  fireFoxVersion = "68";
  safariVersion = "8";

  constructor(private httpClient: HttpClient) {
    var server_url = config["server_url"];
    
    // this.url = "http://" + server_url + ":9000";
    // this.wsUrl = "ws://" + server_url + ":8101";
    this.url = "https://" + server_url + ":9000";
    this.wsUrl = "wss://" + server_url + ":8101";

    this.linkedInClientID = config["linkedInClientID"];
    this.linkedInUrl =
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
      this.linkedInClientID +
      "&redirect_uri=https://" +
      server_url +
      "&scope=r_emailaddress";
    this.analyticsUrl = config["analytics_url"];
  }

  logout() {
    localStorage.setItem("token", "");
    this.logged = false;
  }

  isAuthenticated(): boolean {
    this.token = localStorage.getItem("token");
    if (this.token) {
      return true;
    } else {
      return false;
    }
  }

  login(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/login", postData, {
      headers,
    });
  }

  forgotPassword(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/login/forgot", postData, {
      headers,
    });
  }

  header() {
    const token = localStorage.getItem("token");
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.set("Content-Type", "application/json; charset=utf-8");
      headers = headers.set("Authorization", "Bearer " + token);
      return headers;
    } else {
      console.log("please login");
    }
  }

  public getDbVersion() {
    const headers = this.header();
    return this.httpClient.get<any>(this.url + "/dbVersion", { headers });
  }

  public getBookmark() {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.get<any>(this.url + "/bookmark/" + userID, {
      headers,
    });
  }

  public getRecents() {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.get<any>(this.url + "/recents/" + userID, {
      headers,
    });
  }

  public getCountries() {
    const headers = this.header();
    return this.httpClient.get<any>(this.url + "/country", { headers });
  }

  public getCountryAsc(countryName) {
    const headers = this.header();
    return this.httpClient.get<any>(this.url + "/countryAssc/" + countryName, {
      headers,
    });
  }

  public getCompanies() {
    const headers = this.header();
    return this.httpClient.get<any>(this.url + "/company", { headers });
  }

  public getPersons() {
    const headers = this.header();
    return this.httpClient.get<any>(this.url + "/person", { headers });
  }

  public getQuickDataforApp(start, end) {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.get<any>(
      this.url + "/quickSearch/" + userID + "/" + start + "/" + end,
      { headers }
    );
  }

  public getQuickData(page) {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.get<any>(
      this.url + "/quickSearch/" + userID + "/" + page,
      { headers }
    );
  }

  public addBookmark(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/bookmark", postData, {
      headers,
    });
  }

  public unBookmark(name) {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.delete<any>(
      this.url + "/bookmark/" + userID + "/" + name,
      { headers }
    );
  }

  public deleteBookmarks() {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.delete<any>(this.url + "/bookmark/" + userID, {
      headers,
    });
  }

  public deleteRecents() {
    const headers = this.header();
    const userID = this.getUserID();
    return this.httpClient.delete<any>(this.url + "/recents/" + userID, {
      headers,
    });
  }

  // public getDetail(type, name) {
  //   const headers = this.header();
  //   const userID = this.getUserID();
  //   let params = new HttpParams();
  //   params = params.append("type", type);
  //   params = params.append("name", name);
  //   params = params.append("userid", userID);
  //   return this.httpClient.get<any>(
  //     this.url + "/details/", { headers: headers, params: params }

  //   );
  // }

  public getDetail(type, id) {
    const headers = this.header();
    const userID = this.getUserID();
    let params = new HttpParams();
    params = params.append("type", type);
    params = params.append("id", id);
    params = params.append("userid", userID);
    return this.httpClient.get<any>(
      this.url + "/details/", { headers: headers, params: params }

    );
  }

  // public getDetail(type, name) {
  //   const headers = this.header();
  //   const userID = this.getUserID();
    
  //   return this.httpClient.get<any>(
  //     this.url + "/details/" + type + "/" + name + "/" + userID,
  //     { headers }
  //   );
  // }

  public postRecentData(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/recents", postData, {
      headers,
    });
  }

  public socialLogin(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/login/social", postData, {
      headers,
    });
  }

  public register(postData) {
    const headers = this.header();
    return this.httpClient.post<any>(this.url + "/register", postData, {
      headers,
    });
  }

  public getToken() {
    return localStorage.getItem("token");
  }

  public getUserID() {
    return localStorage.getItem("userId");
  }

  public getEmail() {
    return this.userEmail;
  }

  public getUserName() {
    return this.name;
  }

  public getUserType() {
    return this.userType;
  }
}
