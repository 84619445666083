let config = {
    "server_url": "app.sanctionrules.com",
    
    // "server_url": "app.sanctionrules.dev.nw.xaglabs.com",
    // "server_url": "localhost",
    "analytics_url": "https://analytics.google.com/analytics/web/?hl=en_US#/p243381897/reports/home?params=_u..nav%3Dga1-experimental",
    // "linkedInClientID": "86rz0luj22exub"
    "linkedInClientID": "86ruoib1yi7yf8"
}

module.exports = config;