import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { DataService } from '../services/data.service';
import { ApiServiceService } from '../services/api-service.service';
import { HomeComponent } from '../home/home.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})

export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;
  @ViewChild('aboutModal', { static: false }) aboutModal: any;
  @ViewChild('loginModal1', { static: false }) loginModal1: any;
  @ViewChild('offlineModal', { static: false }) offlineModal: any;
  @ViewChild('installModal', { static: false }) installModal: any;
  @ViewChild('connectivityModal', { static: false }) connectivityModal: any;
  @ViewChild('cookieAlertModal', { static: false }) cookieAlertModal: any;
  @ViewChild('forgotPassModal', { static: false }) forgotPassModal: any;
  @ViewChild('sessionExpiryModal', { static: false }) sessionExpiryModal: any;
  @ViewChild('forgotEmailSuccessModal', { static: false }) forgotEmailSuccessModal: any;
  dbVersion: string;
  dbLastUpdatedOn: string;
  loginModal = false;
  localName;
  localEmail;
  forgotEmail;
  emailNotFound = false;
  successForgot = false;
  email_id;
  responseMessage;
  name;
  userNotFound = false;
  registerModal = false;
  picture;
  searchText: string;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  phoneNumber = "^(?:[0-9]●?){6,14}";
  locations: any;
  loginResponse;
  wrongPass = false;
  emailAlreadyExist = false;
  nameAlreadyExist = false;
  validatingForm: FormGroup;
  username = new FormControl('', []);
  email = new FormControl('', []);
  pass = new FormControl('', []);
  phone = new FormControl('', []);
  location = new FormControl('', []);
  regesteredSuccess: boolean = false;
  rememberMe = false;
  password;
  checked;
  userId: string;
  userEmail: string;
  token: string;
  headers: any;
  type;
  logged: boolean;
  eye = true;
  emailError: boolean = false;
  url = this.apiService.url;
  userType: any;
  year: any;
  assetsPath = this.apiService.assetsPath;
  timeout: any;
  timeoutOfflineModal = this.apiService.timeoutOfflineModal;
  timeoutConnectivityModal = this.apiService.timeoutConnectivityModal;

  config :any;
  dataModel;
  locationNames = [];
  isMobile: boolean = false;


  constructor(
    private modalService: ModalService,
    private afAuth: AngularFireAuth,
    private dataService: DataService,
    private deviceService: DeviceDetectorService,
    private apiService: ApiServiceService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private home: HomeComponent) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {

     // clearing history once comes to dashboard only in mobile
    //  if (this.deviceService.isMobile()) {
    //   this.isMobile = true;
    // }
    console.log(window.matchMedia('(display-mode: standalone)').matches);

    if((window.matchMedia('(display-mode: standalone)').matches) == true){
      this.isMobile = true;
    } else {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        //alert("Please Download Sanction Rules app from play store for better performance.");
        this.openModal('installModal');
        setTimeout(() => {
          this.closeModal('offlineModal');
        }, 10000);
      }else{
        // false for not mobile device
        //alert("not mobile device");
      }
      // alert("Please Download app from play store <a href='www.google.com' target='_blank'>Google</a> for better performance.")
    }

    // get this year
    this.year = this.dataService.getYear();

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

    // register form
    this.validatingForm = this.formBuilder.group({
      username: this.username,
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: this.pass,
      phone: new FormControl('', [Validators.required, Validators.pattern(this.phoneNumber)]),
      location: this.location
    });
  }

  backToLoginModal(){
    this.closeModal('forgotPassModal');
    this.openModal('loginModal');
    this.successForgot = false;
    this.emailNotFound = false;
    this.forgotEmail = '';
  }

  openForgotPassModal() {
    this.closeModal('loginModal');
    this.openModal('forgotPassModal');
    this.successForgot = false;
    this.emailNotFound = false;
    this.forgotEmail = '';
    $('#forgotEmail').focus();
  }

  forgotPassword(value) {

    if (value.forgotEmail.length != 0 && value.forgotEmail.length < 500) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.forgotEmail) !== false) {
        this.emailError = false;
        setTimeout(() => {
          this.emailError = false;
        }, 2000);
      } else {
        this.emailError = true;
        setTimeout(() => {
          this.emailError = false;
        }, 2000);
      }
    }

    if(!this.emailError && value.forgotEmail.length !== 0) {
      const jsonData = {
        email: value.forgotEmail
      };
      this.email_id = value.forgotEmail;
      this.dataService.showLoader();

      this.apiService.forgotPassword(jsonData).toPromise().then(data => {
        if (data.message === 'Success') {
          //this.successForgot = true;
          this.dataService.hideLoader();
          this.closeModal('forgotPassModal');
          this.openModal('forgotEmailSuccessModal');
        }
      },
      err => {
        // check error status code is 500, if so, do some action
        const errorMsg = err.error.message;

        if (errorMsg === 'Email not found') {
          this.emailNotFound = true;
          setTimeout(() => {
            this.emailNotFound = false;
            this.dataService.hideLoader();
          }, 2000);

        } else {
          // console.log('Something Went Wrong.');
          setTimeout(() => {
            this.dataService.hideLoader();
          }, 2000);
        }
      });
    }

  }

  /** Login */
  login(values: { pass: any; uname: any; }) {


    if (this.dataService.checkInternet()) {
      // password AES encryption
      var password = CryptoJS.AES.encrypt(values.pass, 'secret key sr');

      const jsonData = {
        username: values.uname,
        password: password.toString()
      };
      this.dataService.showLoader();
      this.timeout = setTimeout(() => {
        if ($('#overlay').is(':visible')) {
          $('#overlay').hide();
          this.openModal('connectivityModal');
        }
      }, this.timeoutConnectivityModal);

      // post method

      this.apiService.login(jsonData).toPromise().then(data => {
        if (data.message === 'Success') {
          if (data.type === 'admin') {
            // console.log('Admin logged in')
          } else {
            // console.log('User logged in');
          }

          // store in cookies
          this.storeDataToLocalStorage(data, 'manual');

          var name = data.name;
          const nameUpperCase = name.toUpperCase();
          /** log to analytics */
          this.dataService.analyticsSetUserID(nameUpperCase);
          this.name = name.charAt(0).toUpperCase() + name.slice(1);

          $('.mobileIcons').hide();

          // remember me check
          if (this.rememberMe == true) {
            localStorage.setItem('rememberme', "true");
            localStorage.setItem('username', this.name);
            localStorage.setItem('password', password.toString());
          }

          if (this.rememberMe == false) {
            localStorage.setItem('rememberme', "false");
            localStorage.setItem('username', '');
            localStorage.setItem('password', '');
            this.name = '';
            this.password = '';
            this.checked = false;
          }
          this.home.ngOnInit();
        }
        setTimeout(() => {
          this.dataService.hideLoader();
          clearTimeout(this.timeout);
        }, 500);
      },
        err => {
          // check error status code is 500, if so, do some action
          const errorMsg = err.error.message;
          setTimeout(() => {
            this.dataService.hideLoader();
            clearTimeout(this.timeout);
          }, 500);
          if (errorMsg === 'Username not found') {
            this.loginResponse = 'User Name not found';
            this.userNotFound = true;
            setTimeout(() => {
              this.userNotFound = false;
            }, 2000);
            setTimeout(() => {
              this.dataService.hideLoader();
            }, 500);

          } else if (errorMsg === 'Wrong password') {
            this.loginResponse = "Wrong Password";
            this.wrongPass = true;
            setTimeout(() => {
              this.wrongPass = false;
              this.dataService.hideLoader();
            }, 2000);
          }
        });
    } else {
      setTimeout(() => {
        this.dataService.hideLoader();
        this.openModal('offlineModal');
        setTimeout(() => {
          this.closeModal('offlineModal');
        }, this.timeoutOfflineModal);
      }, 500);
    }

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  selectedLocation(loc) {
    // console.log(loc);
  }

  alphaConsult() {
    window.open('http://www.alphaconsultltd.com', '_target');
  }

  /** Hide and show password */
  showHidePassword() {
    const x = (document.getElementById('pass') as HTMLInputElement);
    if (x.type === 'password') {
      x.type = 'text';
      this.eye = false;
    } else {
      x.type = 'password';
      this.eye = true;
    }
  }

  emailValidation() {
    const emailId = this.validatingForm.get('email').value;
    if (emailId.length != 0 && emailId.length < 500) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId) !== false) {
        this.emailError = false;
      } else {
        this.emailError = true;
      }
    }
  }


  // on change of remember me value
  checkboxChange(eve) {
    this.rememberMe = eve;
  }

  loginModalOpen() {
    this.checkSavedCredentials();
    const x = (document.getElementById('pass') as HTMLInputElement);

    if (x != null && x.type === 'text') {
      x.type = 'password';
      this.eye = true;
    }

    this.loginModal = true;
    this.registerModal = false;
  }

  registerModalOpen() {
    this.dataModel = '';
    this.locations = this.dataService.getLocations();
    for(let location of this.locations){
      this.locationNames.push(location.name);
    }
    this.config = {
      displayKey:"description", //if objects array passed which key to be displayed defaults to description
      search:true,//true/false for the search functionlity defaults to false,
      height: '350px',//height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder:'Select your Country', // text to be displayed when no item is selected defaults to Select,
      customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: this.locationNames.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder:'Search here', // label thats displayed in search input,
      searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      multiple: false, //true/false beased if multiple selection required or not Defaults to false
      }
    this.loginModal = false;
    this.registerModal = true;
    this.validatingForm.reset();
  }

  backtoLoginModal() {
    this.loginModal = true;
    this.registerModal = false;
    this.emailError = false;
  }

  bodyScrollNone() {
    $('#sectionScroll').css('position', 'fixed');
  }

  /** Gmail login in Web*/
  googleLoginWeb() {
    if (navigator.onLine) {
      let promise = new Promise<any>((resolve, reject) => {
        let provider = new auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        this.afAuth.auth
          .signInWithPopup(provider)
          .then(res => {
            resolve(res);
          }, err => {
            //this.dataService.showLoader();
            if(err.message == 'This browser is not supported or 3rd party cookies and data may be disabled.'){
              //alert("Please allow 3rd party cookies in your browser setting and try again.");
              this.openModal('cookieAlertModal');
            }
            reject(err);
          });
      });

      promise.then((result) => this.socialLoginAPI(result, 'gmail'));
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }

  }

  /** Facebook Login in Web */
  loginFacebookWeb() {
    if (navigator.onLine) {
      let promise = new Promise<any>((resolve, reject) => {
        let provider = new auth.FacebookAuthProvider();
        this.afAuth.auth
          .signInWithPopup(provider)
          .then(res => {
            this.dataService.showLoader();
            resolve(res);
          }, err => {
            //this.dataService.showLoader();
            if(err.message == 'This browser is not supported or 3rd party cookies and data may be disabled.'){
              //alert("Please allow 3rd party cookies in your browser setting and try again.");
              this.openModal('cookieAlertModal');
            }
            reject(err);
          });
      });

      promise.then((result) => this.socialLoginAPI(result, 'fb'));
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }

  }

  homePage() {
    this.dataService.removeBottomBorder();
    setTimeout(() => {
      this.dataService.homePage();
    }, 200);
  }

  homePageWithLogout() {
    this.dataService.removeBottomBorder();
    setTimeout(() => {
      this.dataService.logout();
      this.dataService.homePage();
      setTimeout(() => {
        window.location.reload();
      }, 50);
    }, 200);
  }

  homeRefresh() {
    this.dataService.removeBottomBorder();
    setTimeout(() => {
      this.dataService.homePage();
      window.opener.location.reload();
    }, 200);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  twitterLoginWeb() {
    if (navigator.onLine) {
      const promise = new Promise<any>((resolve, reject) => {
        const provider = new auth.TwitterAuthProvider();

        this.afAuth.auth
          .signInWithPopup(provider)
          .then(res => {
            resolve(res);
          }, err => {
            //this.dataService.showLoader();
            if(err.message == 'This browser is not supported or 3rd party cookies and data may be disabled.'){
              //alert("Please allow 3rd party cookies in your browser setting and try again.");
              this.openModal('cookieAlertModal');
            }
            reject(err);
          });
      });

      promise.then((result) => this.socialLoginAPI(result, 'twitter'));
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }

  }

  /** LiknkedIn Login */
  linkedInLogin(winName, w, h, scroll): void {
    if (navigator.onLine) {
      const url = this.apiService.linkedInUrl;
      const LeftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
      const TopPosition = (screen.height) ? (screen.height - h) / 2 : 0;
      const settings =
        'height=' + h + ',width=' + w + ',top=' + TopPosition + ',left=' + LeftPosition + ',scrollbars=' + scroll + ',resizable'
      window.open(url, winName, settings);
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }

  }

  /** get Info from social login response */
  socialLoginAPI(result, loginType) {
    let email;
    let name;
    let device_platform;

    if (loginType === 'mobileGmail' || loginType === 'mobileFb') {
      email = result.email;
      this.picture = result.photoUrl;
      name = result.name;
    } else {
      const profile = result.additionalUserInfo.profile;

      if (loginType === 'twitter') {
        this.picture = profile.profile_image_url;
        name = profile.name;
        email = profile.email;
        if (email == undefined) {
          email = name + '@dummy.twitter.com';
        }
      } else if (loginType === 'fb') {
        this.picture = profile.picture.data.url;
        name = profile.name;
        email = profile.email;
        if (email == undefined) {
          email = name + '@dummy.facebook.com';
        }
      } else {
        email = profile.email;
        this.picture = profile.picture;
        name = profile.name;
      }
    }

    if(this.isMobile){
      device_platform = "mobile"
    } else {
      device_platform = "web"
    }

    const jsonDataReg = {
      "name": name,
      "email": email,
      "loginType": loginType,
      "platform": device_platform
    }

    /** Login API on social login response */

    this.apiService.socialLogin(jsonDataReg).toPromise().then(data => {
      // console.log("social login response:")
      if(data.message == "Install app"){
        alert("You are not a registered user. Please install Sanction Rules App from Android Play Store to continue.")
      } else {
        // console.log("in else")
        localStorage.setItem('picture', this.picture);
        this.storeDataToLocalStorage(data, loginType);
      }

    });

  }

  /** Store cookies */
  storeDataToLocalStorage(data, loginType) {
    // console.log("store data to local storage")
    this.token = data.token;
    this.userEmail = data.email;
    if (this.userEmail.includes('dummy')) {
      this.userEmail = 'NULL';
    }
    this.userId = data.userId;
    this.userType = data.type;

    // Save login type
    localStorage.setItem('loginType', loginType);

    // Save user type
    localStorage.setItem('userType', this.userType);

    // save token in local storage
    localStorage.setItem('token', this.token);

    this.getDbVersion();

    var name = data.name;

    name = name.charAt(0).toUpperCase() + name.slice(1);

    // save user name in local storage
    localStorage.setItem('localName', name);

    // savw user name
    this.localName = localStorage.getItem('localName');

    // save user ID in local storage
    localStorage.setItem('userId', this.userId);

    // save user email in local storage
    localStorage.setItem('userEmail', this.userEmail);

    this.localEmail = localStorage.getItem('userEmail');

    // login status
    this.logged = true;

    //closing Login Model on Success Login
    $(".close").click();
    setTimeout(() => {
      this.dataService.hideLoader();
    }, 500);

    // this.apiService.getConfiguration().toPromise().then(res => {
    //   this.apiService.setConfiguration(res);
    // })
    this.home.ngOnInit();
  }

  /** Get token from cookies */
  getToken() {
    return localStorage.getItem('token');
  }

  /** Get userID from cookies */
  getUserID() {
    return localStorage.getItem("userId");
  }

  bodyScroll() {
    $('#sectionScroll').css('position', 'relative');
  }

  /** Register */
  onRegister() {
    if (navigator.onLine) {
      var regData = this.validatingForm.value;
      regData['loginType'] = 'manual';
      this.timeout = setTimeout(() => {
        if ($('#overlay').is(':visible')) {
          $('#overlay').hide();
          this.openModal('connectivityModal');
        }
      }, this.timeoutConnectivityModal);
      this.dataService.showLoader();

      this.apiService.register(regData).toPromise().then(data => {
        if (data.message === 'successfully registered') {
          $("#registeredStatus").css({ "background-color": "green" });
          this.responseMessage = "Successfully Registered";
          this.registerResponse();
          $('#registeredForm').get(0).reset();
          this.dataService.hideLoader();
          clearTimeout(this.timeout);
          this.backtoLoginModal();
        }
        else if (data.message === 'sent') {
          this.responseMessage = "Email sent to " + this.validatingForm.value.email;
          this.registerResponse();
          this.dataService.hideLoader();
          clearTimeout(this.timeout);
          this.backtoLoginModal();
        }
        setTimeout(() => {
          this.dataService.hideLoader();
          clearTimeout(this.timeout);
        }, 500);
      },
        err => {
          console.log(err)

          // check error status code is 500, if so, do some action
          const errorMsg = err.error.message;
          if (errorMsg === 'Username already exists') {
            $("#registeredStatus").css({ "background-color": "red" });
            this.responseMessage = "User Name Already Exists";
            this.nameAlreadyExist = true;
            setTimeout(() => {
              this.nameAlreadyExist = false;
            }, 2000)
            this.dataService.hideLoader();
            clearTimeout(this.timeout);
          }
          else if (errorMsg === 'Email id already exists') {
            $('#registeredStatus').css({ "background-color": "orange" });
            this.responseMessage = "Email ID Already Exists";
            this.emailAlreadyExist = true;
            setTimeout(() => {
              this.emailAlreadyExist = false;
            }, 2000)
            this.dataService.hideLoader();
            clearTimeout(this.timeout);
          } else if (errorMsg === 'error in send') {
            this.responseMessage = "Please Check Email ID";
            this.registerResponse();
            this.dataService.hideLoader();
            clearTimeout(this.timeout);
            //this.backtoLoginModal();
          } else {
            this.closeModal('loginModal');
          }
        })
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }
  }

  // validate email
  get input() { return this.validatingForm.get('email'); }

  /** Check Saved Credentials from cookies */
  checkSavedCredentials() {
    const remember = localStorage.getItem('rememberme');

    if (remember === 'true') {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('password'), 'secret key sr');
      this.password = bytes.toString(CryptoJS.enc.Utf8);
      this.name = localStorage.getItem('username');
      this.checked = true;
      this.rememberMe = true;
      localStorage.setItem('rememberme', "true");
      localStorage.setItem('username', this.name);
    }
  }

  registerResponse() {
    this.regesteredSuccess = true;

    setTimeout(() => {
      this.regesteredSuccess = false;
      $("#registeredStatus").delay(500).slideUp(200, function () {
      });
    }, 3000)
  }


  getDbVersion() {
    this.apiService.getDbVersion().subscribe(
      data => {
        this.dataService.setInfoData(data.dbVersion[0])
      },
      error => {
        console.log(error)
      }
    );
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  close(name): void {
    if (name == 'aboutModal') {
      this.aboutModal.hide();
    }

    if (name == 'offlineModal') {
      this.offlineModal.hide();
    }

    if (name == 'installModal') {
      this.installModal.hide();
    }

    if (name == 'loginModal') {
      this.loginModal1.hide();
    }

    if (name == 'connectivityModal') {
      this.connectivityModal.hide();
    }

    if (name == 'cookieAlertModal') {
      this.cookieAlertModal.hide();
    }

    if (name == 'forgotPassModal') {
      this.forgotPassModal.hide();
    }

    if (name == 'sessionExpiryModal') {
      this.sessionExpiryModal.hide();
    }

    if (name == 'forgotEmailSuccessModal') {
      this.forgotEmailSuccessModal.hide();
    }

  }

  // open modal
  open(name): void {
    if (name == 'aboutModal') {
      this.aboutModal.show();
      $('.aboutBody').animate({ scrollTop: 0 }, 'slow');
      this.dbVersion = localStorage.getItem('dbVersion');
      this.dbLastUpdatedOn = localStorage.getItem('dbLastUpdatedOn');
    }

    if (name == 'offlineModal') {
      this.offlineModal.show();
    }

    if (name == 'installModal') {
      this.installModal.show();
    }

    if (name == 'loginModal') {
      this.loginModal1.show();
      this.loginModalOpen();
    }

    if (name == 'connectivityModal') {
      this.connectivityModal.show();
    }

    if (name == 'cookieAlertModal') {
      this.cookieAlertModal.show();
    }

    if (name == 'forgotPassModal') {
      this.forgotPassModal.show();
    }

    if (name == 'sessionExpiryModal') {
      this.sessionExpiryModal.show();
    }

    if (name == 'forgotEmailSuccessModal') {
      this.forgotEmailSuccessModal.show();
    }

  }

}
