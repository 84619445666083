let configuration = {
    apiKey: "AIzaSyAWyuyPi562GR2u4wARRbIPwmY7viNNZ5g",
    authDomain: "sanction-rules-286509.firebaseapp.com",
    databaseURL: "https://sanction-rules-286509.firebaseio.com",
    projectId: "sanction-rules-286509",
    storageBucket: "sanction-rules-286509.appspot.com",
    messagingSenderId: "973478151720",
    appId: "1:973478151720:web:d7a3492b508a74e6e7571d",
    measurementId: "G-4TJBK5YRKR"
  }

  module.exports = configuration;