import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    'searchApp': true,
    'countryAsc': true,
    'company': true,
    'individual': true,
    'search': true,
  };
  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {

    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'searchApp') {
      this.allowRetriveCache['searchApp'] = true;
    } else {
      this.allowRetriveCache['searchApp'] = false;
    }

    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'countryAsc') {
      this.allowRetriveCache['countryAsc'] = true;
    } else {
      this.allowRetriveCache['countryAsc'] = false;
    }

    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'company') {
      this.allowRetriveCache['company'] = true;
    } else {
      this.allowRetriveCache['company'] = false;
    }

    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'individual') {
      this.allowRetriveCache['individual'] = true;
    } else {
      this.allowRetriveCache['individual'] = false;
    }

    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'search') {
      this.allowRetriveCache['search'] = true;
    } else {
      this.allowRetriveCache['search'] = false;
    }

    return before.routeConfig === curr.routeConfig;
  }


  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetriveCache[path]) {
      return this.storedRouteHandles.has(this.getPath(route));
    }

    return false;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetriveCache.hasOwnProperty(path)) {
      return true;
    }
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }
  private getPath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig !== null && route.routeConfig.path !== null) {
      return route.routeConfig.path;
    }
    return '';
  }
}
