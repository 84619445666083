import { ModalService } from './services/modal.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SliderModule } from 'angular-image-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LinkedInComponent } from './linked-in/linked-in.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxPaginationModule } from 'ngx-pagination';
import { CookieService } from 'ngx-cookie-service';
import 'hammerjs';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Pipe, PipeTransform } from '@angular/core';


declare var require: any;
let configuration = require('../assets/firebase_config');
var obj = {};
obj['apiKey'] = configuration['apiKey']
obj['authDomain'] = configuration['authDomain']
obj['databaseURL'] = configuration['databaseURL']
obj['projectId'] = configuration['projectId']
obj['storageBucket'] = configuration['storageBucket']
obj['messagingSenderId'] = configuration['messagingSenderId']
obj['appId'] = configuration['appId']
obj['measurementId'] = configuration['measurementId']

export const firebaseConfig = obj;

import * as firebase from 'firebase/app';
import { ModalComponent } from './modal/modal.component';
import { BrowserSupportComponent } from './browser-support/browser-support.component';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';;
import { ScrollingModule } from '@angular/cdk/scrolling';

firebase.initializeApp(firebaseConfig);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LinkedInComponent,
    ModalComponent,
    BrowserSupportComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    SelectDropDownModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxPaginationModule,
    SliderModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    InfiniteScrollModule,
    Ng2SearchPipeModule
  ],

  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },

    CookieService,
    ModalService

  ],

  bootstrap: [AppComponent]

})



export class AppModule {
  constructor() {
    console.log('app module loads')
  }
}
