import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LinkedInComponent } from './linked-in/linked-in.component';
import { BrowserSupportComponent } from 'src/app/browser-support/browser-support.component';


const routes: Routes = [
    {path: '' , loadChildren : './cards/cards.module#CardsModule' },
    {path: 'auth/linkedin/callback' , component : LinkedInComponent },
    {path: 'browser-support' , component : BrowserSupportComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true , scrollPositionRestoration: 'disabled' })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
