import { ApiServiceService } from 'src/app/services/api-service.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-linked-in',
  templateUrl: './linked-in.component.html',
  styleUrls: ['./linked-in.component.scss']
})
export class LinkedInComponent implements OnInit {

  message;
  code;
  isMobileDevice: boolean = false;
  isMobile: boolean = false;
  constructor(private http: HttpClient, private apiService: ApiServiceService, private _location: Location, private cookieService: CookieService, private router: Router, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params) => {
      this.code = params.code;
    })

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.get<any>(this.apiService.url + '/linkedin/' + this.code, { headers }).subscribe(
      data => {
        if (data.message === 'Invalid access token') {
          alert('Login Failed')
          this.message = 'Invalid access token';
          window.close();
        }
        if (data.message === 'Success') {
          const email = data.res.elements[0]['handle~'].emailAddress;
          const name = email.split('@')[0];
          let device_platform;
 
          if((window.matchMedia('(display-mode: standalone)').matches) == true){
            this.isMobile = true;
          }
 
          if(this.isMobile){
            device_platform = "mobile"
          } else {
            device_platform = "web"
          }
          const jsonDataReg = {
            "name": name,
            "email": email,
            "loginType": 'linkedin',
            "platform": device_platform
          }

          this.http.post(this.apiService.url + '/login/social', jsonDataReg).toPromise().then(data1 => {
            if (data1["message"] == "Install app") {
              window.close();
              alert("You are not a registered user. Please install Sanction Rules App from Android Play Store to continue.")
            } else {
              this.storeDataToLocalStorage(data1, 'linkedIn');
              window.close();
              this.RefreshParent();
            }

          });
        }
      },
      error => console.error('There was an error!', error)
    );
  }

  storeDataToLocalStorage(data, loginType) {

    // Save login type
    localStorage.setItem('loginType', loginType);

    // Save user type
    localStorage.setItem('userType', data.type);

    // save token in local storage
    localStorage.setItem('token', data.token);

    // save user name in local storage
    localStorage.setItem('localName', data.name);

    // save user ID in local storage
    localStorage.setItem('userId', data.userId);

    // save user email in local storage
    localStorage.setItem('userEmail', data.email);

    /** log to analytics */
    // setUserID(nameUpperCase);
    // setUserName(nameUpperCase);
    // logEvent('logged in');

  }

  RefreshParent() {
    if (window.opener != null && !window.opener.closed) {
      window.opener.location.reload();
    }
  }

}
