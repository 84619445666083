import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { SwUpdate } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription } from 'rxjs';
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';
import { slideInAnimation } from '../route-animation';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [slideInAnimation]
})

export class HomeComponent implements OnInit {

  url = this.apiService.url;
  year;
  localName;
  localEmail;
  userType: any;
  userId;
  token;
  logged;
  cookieValue = 'UNKNOWN';
  message: any;
  offline: boolean = false;
  subscription: Subscription;
  code;
  dbVersion;
  dbLastUpdatedOn;
  dbSrcLastUpdatedOn;
  title = 'app';
  picture;
  nameUpperCase;
  logoutSuccess = false;
  assetsPath = this.apiService.assetsPath;
  webIconsPath = this.apiService.webIconsPath;
  carouselPath = this.apiService.carouselPath;
  timeoutOfflineModal = this.apiService.timeoutOfflineModal;
  lastSrcUpdate;
  lastUpdateDate = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private cookieService: CookieService,
    private dataService: DataService,
    private apiService: ApiServiceService,
    //updates: SwUpdate,
  ) {

    // updates.available.subscribe(event => {
    //   updates.activateUpdate().then(() => {
    //     document.location.reload();
    //   });
    // });

    const source = interval(1000);
    this.subscription = source.subscribe(val => this.checkInternet());
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  login() {
    if (navigator.onLine) {
      this.modalService.open('loginModal');
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }
  }

  homePage() {
    this.router.navigate(['/'])
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  // Check internet connection
  checkInternet() {
    if (navigator.onLine) {
      this.offline = false;
    } else {
      this.offline = true;
    }
  }

  // on internet connect
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  countryPage() {
    //if (navigator.onLine) {
      if (this.apiService.isAuthenticated()) {
        
        this.dataService.removeBottomBorder();
        $('img#countryImg').css('border-bottom', '3px solid #0083b0');
        $('img#countryImg').css('padding', '3px');
        this.router.navigate(['/country']);
      } else {
        console.log("please login")
      }
  }

  companyPage() {
    localStorage.setItem('currentPage', "1");  
    //if (navigator.onLine) {
      if (this.apiService.isAuthenticated()) {
        this.dataService.removeBottomBorder();
        $('img#companyImg').css('border-bottom', '3px solid #0083b0');
        $('img#companyImg').css('padding', '3px');
        this.router.navigate(['/company']);
      } else {
        this.logged = false;
        console.log("please login")
      }

  }


  personPage() {
    //if (navigator.onLine) {
      localStorage.setItem('currentPage', "1");  
      if (this.apiService.isAuthenticated()) {
        this.dataService.removeBottomBorder();
        $('img#personImg').css('border-bottom', '3px solid #0083b0');
        $('img#personImg').css('padding', '3px');
        this.router.navigate(['/individual']);
      } else {
        console.log("please login")
      }

  }

  searchPage() {
    localStorage.setItem('currentPage', "1"); 
    if (navigator.onLine) {
      if (this.apiService.isAuthenticated()) {
        this.dataService.removeBottomBorder();
        $('img#advSerImg').css('border-bottom', '3px solid #0083b0');
        $('img#advSerImg').css('padding', '3px');
        this.router.navigate(['/search']);
      } else {
        console.log("please login")
      }
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }


  }


  searchPageApp() {
    if (navigator.onLine) {
      this.router.navigate(['/searchApp']);
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }

  }

formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
  
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
  
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  // on initialiase
  ngOnInit() {
    // var date = new Date();
    // lastUpdatedDate = this.formatDate()

    //linkedIn login
    this.code = window.location.href.split('code=')[1]; //auth code of linked in

    if (this.code != undefined) {
      // navigate to linked in component
      this.router.navigate(['auth/linkedin/callback'], { queryParams: { code: this.code } });
    }

    $('div#sideMenu').hide();
    // share menu to hide and show
    if ($('.shareClick').is(':visible')) {
      $('.shareClick').hide();
    }

    // token
    this.token = this.getToken();

    // profile picture of social login
    this.picture = localStorage.getItem('picture');
    // get this year
    this.year = this.dataService.getYear();

    // check token is available on not
    if (this.token === '' || this.token === null || this.token === undefined) {
      this.logged = false;
    } else {

      this.logged = true;
      // get some data from cookies on initialize
      this.localName = localStorage.getItem('localName');
      this.localEmail = localStorage.getItem('userEmail')
      this.userId = localStorage.getItem('userId');
      this.userType = localStorage.getItem('userType');
      if (localStorage.getItem('dbVersion') == undefined) {
        this.dataService.getAll();
      }
      this.getDbVersion();
    }

    //get username
    const username = localStorage.getItem('localName');
    if (username != null) {
      this.nameUpperCase = username.toUpperCase();

      /** Writing into analytics */
      this.dataService.analyticsSetUserID(this.nameUpperCase);
    }
  }

  sideMenuMobile() {
    if ($('.box').is(':visible')) {
      $('.box').hide();
    } else {
      $(".box").show();
      this.logged = this.logged;
    }
  }

  getDbVersion() {
    this.apiService.getDbVersion().subscribe(
      data => {
        this.dataService.setInfoData(data.dbVersion[0])
        this.dbVersion = data.dbVersion[0]['db_version'];
        // this.dbSrcLastUpdatedOn = data.dbVersion[0]['src_last_updated_on'];
        this.dbLastUpdatedOn = data.dbVersion[0]['db_last_updated_on'];
        // var dateString = this.dbSrcLastUpdatedOn.split('-')[2]+"-"+this.dbSrcLastUpdatedOn.split('-')[1]+"-"+this.dbSrcLastUpdatedOn.split('-')[0];
        var dateString = this.dbLastUpdatedOn.split('-')[2]+"-"+this.dbLastUpdatedOn.split('-')[1]+"-"+this.dbLastUpdatedOn.split('-')[0];
        var date = new Date(dateString);
        this.lastSrcUpdate = this.formatDate(date);
        if(this.lastSrcUpdate != undefined) {
          this.lastUpdateDate = true;
        }
        
      },
      error => {
        console.log(error)
      }
    );
  }

  /** Get token from cookies */
  getToken() {
    return localStorage.getItem('token');
  }

  logout() {
    this.dataService.logout();
    this.logoutResponse();
    this.ngOnInit();
    this.dataService.removeBottomBorder();
    this.router.navigate(['/']);
  }


  logoutResponse() {
    this.logoutSuccess = true;

    setTimeout(() => {
      this.logoutSuccess = false;
      $("#logoutStatus").delay(500).slideUp(200, function () {
      });
    }, 1000)
  }

  // open analytics in another tab
  analytics() {
    if (navigator.onLine) {
      window.open(this.apiService.analyticsUrl, "_target");
    } else {
      this.dataService.hideListLoader();
      this.openModal('offlineModal');
      setTimeout(() => {
        this.closeModal('offlineModal');
      }, this.timeoutOfflineModal);
    }
  }
}
