import { Router } from '@angular/router';
import { Component, AfterViewInit, Renderer2 } from '@angular/core';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiServiceService } from './services/api-service.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements AfterViewInit {
  headerName: string;
  infoModal: boolean;
  aboutModal: boolean;
  infoData: string;
  portrait = true;
  title = 'web-app';
  browser;
  browserVersion;
  browserNotSupported = false;
  isInStandaloneMode: never;

  constructor(
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private apiservice: ApiServiceService,
    private router: Router ) {
      this.epicFunction();
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.browser = this.deviceService.getDeviceInfo().browser;
    this.browserVersion = this.deviceService.getDeviceInfo().browser_version;
    //console.log(this.browserVersion);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)

    if(this.browser == "Chrome" && this.browserVersion <= this.apiservice.chromeVersion){
      // window.open("https://www.google.com","_self")
      this.browserNotSupported = true;
      this.router.navigate(['browser-support']);
    }

    if(this.browser == "MS-Edge-Chromium || Edge" && this.browserVersion <= this.apiservice.edgeVersion){
      this.browserNotSupported = true;
      this.router.navigate(['browser-support']);
    }

    if(this.browser == "Firefox" && this.browserVersion <= this.apiservice.fireFoxVersion){
      this.browserNotSupported = true;
      this.router.navigate(['browser-support']);
    }

    if(this.browser == "Safari"){
      var browserVersion: number = +this.browserVersion;
      var safariVersion: number = +this.apiservice.safariVersion;
      if(browserVersion <= safariVersion){
        this.browserNotSupported = true;
        this.router.navigate(['browser-support']);
      }
    }

    if(this.browser == "IE"){
      this.browserNotSupported = true;
      this.router.navigate(['browser-support']);
    }
  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    loader.style.display = 'none';
    $('body').css('display', 'block');

    screen.orientation.addEventListener('change', function (e) {
      if (screen.orientation.type === 'landscape-primary' || screen.orientation.angle === 270) {
        $(document).ready(function () {
          $('body').css('display', 'none');
        });
      } else {
        $('body').css('display', 'block');
      }

    }, false);
  }

  ngOnInit() {
    // alert("1: "+(window.matchMedia('(display-mode: standalone)').matches))
    // alert("2: "+(window.matchMedia('(display-mode: fullscreen)').matches))
    // alert("3: "+(window.matchMedia('(display-mode: minimal-ui)').matches))
    // alert("4: "+(window.matchMedia('(display-mode: browser)').matches))
  }

}
